<template>
  <VTradeProcedure
    :url-action-query="`${EXPAND_LOT}&search[Active]=active`"
    :is-chosen-icon="false"
  />
</template>
<script>
import { EXPAND_LOT } from '@/utils/consts'
import VTradeProcedure from '@/components/views/TradeProcedure/VTradeProcedure'
export default {
  setup () {
    return {
      EXPAND_LOT
    }
  },
  components: { VTradeProcedure }
}
</script>
